












































import { Component, Vue } from 'vue-property-decorator'
import GroupHeader from '@/components/Group/GroupHeader.vue'
import { EGroupRegisterType } from '@/models'
import GroupMemberService from '@/services/GroupMemberService'
import GroupService from '@/services/GroupService'

@Component({ components: { GroupHeader } })
export default class GroupPreview extends Vue {
  private groupName: string = ''
  private defaultAvatar = require('@/assets/images/group/avatar_default_1.png')
  created() {
    this.getGroupDetail()
  }

  getGroupDetail() {
    GroupService.checkGroupLink(this.$route.params.groupLink)
      .then(res => {
        if (res.status === 200) {
          this.groupName = res.data.name
        }
      })
      .catch(() => {
        this.$router.push({
          name: 'page-not-found',
          params: { msgCode: 'group_qr_expried' }
        })
      })
  }

  jumpToRegisterPage() {
    const loginedUserId = this.$store.state.userInfo.user.info.id
    if (loginedUserId) {
      GroupMemberService.requestJoin({
        user_id: loginedUserId,
        group_link: this.$route.params.groupLink,
        not_check_belong_group: true
      })
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$bvModal.show('modal-success')
          }
        })
        .catch(error => {
          if (error.response.status === 403) {
            this.$bvModal.show('modal-info')
          }
        })
    } else {
      this.$router.push({
        name: 'register',
        query: {
          type: String(EGroupRegisterType.USER_TEPM_REGISTER),
          group: this.$route.params.groupLink
        }
      })
    }
  }

  jumpToHome() {
    this.$router.push({ name: 'home' })
  }
}
